
export const DOCUMENT_TYPES: { id: string, name: string }[] = [
    { id: "TI", name: "Tarjeta de Identidad"},
    { id: "CC", name: "Cédula de Ciudadanía" },
    { id: "Pasaporte", name: "Pasaporte" },
    { id: "RC", name: "Registro Civil" },
    { id: "CE", name: "Cédula de Extrangería" }
];

export const DOCUMENT_TYPES_COLLABORATOR: { id: string, name: string }[] = [
    { id: "CC", name: "Cédula de Ciudadanía" },
    { id: "Pasaporte", name: "Pasaporte" },
    { id: "CE", name: "Cédula de Extrangería" }
];

export const SYMPTOMS: string[] = [
    "Fiebre mayor a 38 grados",
    "Dificultad para respirar",
    "Sintomas Gripales",
    "Ninguno"
];